<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('payment_sales_invoices.payment_sales_invoices_section')  }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('payment_sales_invoices.payment_method') }}<span class="text-danger">*</span></label>
                <select name="" id="payment_method" v-model="data.payment_method" @change="onPaymentMethodChanged(data.payment_method)" class="custom-select" :class="validation && validation.payment_method ? 'is-invalid' : ''">
                  <option v-for="row in payment_methods" :value="row.id" :key="row.id" :disabled="row.id==0">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.payment_method[0] }}
                </span>
              </div>
              <template>
                <!--  -->
                <div class="col-lg-6 mb-5" v-if="cash_type == 'bank'">
                  <label>{{ $t('payment_sales_invoices.banks') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <multiselect v-model="bank"
                                 :class="validation && validation.method_type_id ? 'is-invalid' : ''"
                                 :placeholder="$t('payment_sales_invoices.banks')"
                                 label="name"
                                 track-by="id"
                                 :options="banks"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend" v-if="$can('banks.create')">
                      <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
<!--                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>-->
                  <span v-if="validation && validation.method_type_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.method_type_id[0] }}
                  </span>
                </div>
                <div class="col-lg-6 mb-5" v-else-if="cash_type == 'treasury'">
                  <label>{{ $t('payment_sales_invoices.treasuries') }}<span class="text-danger">*</span></label>
                  <div class="input-group">
                    <multiselect v-model="treasury"
                                 :class="validation && validation.treasury_id ? 'is-invalid' : ''"
                                 :placeholder="$t('payment_sales_invoices.treasuries')"
                                 label="name"
                                 track-by="id"
                                 :options="treasuries"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false">
                    </multiselect>
                    <div class="input-group-prepend" v-if="$can('treasuries.create')">
                      <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
<!--                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>-->
                  <span v-if="validation && validation.treasury_id" class="fv-plugins-message-container invalid-feedback">
                      {{ validation.treasury_id[0] }}
                  </span>
                </div>
              </template>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.collected_by') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="collected_user"
                               :class="validation && validation.collected_user_id ? 'is-invalid' : ''"
                               :placeholder="$t('payment_sales_invoices.collected_by')"
                               label="name"
                               track-by="id"
                               :options="users"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @search-change="getUsers($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/users/users" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.collected_by') }}.</span>
                <span v-if="validation && validation.collected_user_id" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.collected_user_id[0] }}
                </span>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.code') }}</label>
                <input type="text" v-model="data.code" :disabled="true" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.code[0] }}
                </span>
              </div>


              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.amount') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.amount" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.amount[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.currency') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.currency_id[0] }}
                        </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.payment_date') }}</label>
                <input type="date" v-model="data.payment_date" :disabled="true" class="form-control" :class="validation && validation.payment_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.payment_date" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.payment_date[0] }}
                        </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branch') }}</label>
                <div class="input-group">
                  <multiselect v-model="branch"
                               :placeholder="$t('branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.branch_id[0] }}
                        </span>
              </div>

              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.ref_no') }}</label>
                <input type="text" v-model="data.ref_no" class="form-control" :class="validation && validation.ref_no ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.ref_no" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.ref_no[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('payment_sales_invoices.payment_status') }}</label>
                <select name="" id="customer_id" v-model="data.payment_status" :disabled="true" class="custom-select" :class="validation && validation.payment_status ? 'is-invalid' : ''">
                  <option v-for="row in payment_status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.payment_status" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.payment_status[0] }}
                        </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('payment_sales_invoices.payment_details') }}</label>
                  <textarea name="" id="payment_details" v-model="data.payment_details" class="form-control" :class="validation && validation.payment_details ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.payment_details" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_details[0] }}
                            </span>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('payment_sales_invoices.receipt_notes') }}</label>
                  <textarea name="" id="receipt_notes" v-model="data.receipt_notes" class="form-control" :class="validation && validation.receipt_notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.receipt_notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.receipt_notes[0] }}
                            </span>
                </div>
                <div class="col-lg-4 mb-5">
                  <label>{{ $t('payment_sales_invoices.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('payment_sales_invoices.attachment')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "form-payment_sales_invoices",
  data() {
    return {
      mainRoute: 'sales/payment_sales_invoices',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'sales',

      salesInvoiceRoute: 'sales/sales_invoices',
      purchaseInvoiceRoute: 'purchases/purchases-invoices',
      SalesCreditRoute: 'sales/credits',
      SalesRefundRoute: 'sales/sales_refunds',
      generalSalesRefundRoute: 'sales/general_sales_refunds',
      PurchaseRefundRoute: 'purchases/purchases-refunds',

      data: {
        code: null,
        payment_method: null,
        amount: null,
        payment_date: null,
        payment_status: 1,
        collected_user_id: null,
        ref_no: null,
        payment_details: null,
        receipt_notes: null,
        attachment: null,
        sales_invoice_id: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
        type_id: this.$route.params.id ? this.$route.params.id : this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
        type_type: null,
        treasury_id: null,
        inventory_id: null,
        branch_id: null,
        currency_id: null,
        method_type_id: null,
        method_type_type: null,
      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      type: this.$route.params.type ? this.$route.params.type : null,

      currencies: [],
      payment_methods: [],
      payment_status_list: [],
      users: [],
      treasuries: [],
      treasury: null,
      banks: [],
      bank: null,
      branches: [],
      branch: null,
      inventories: [],
      inventory: null,
      payment_method: null,

      attachment_url: null,
      collected_user: null,
      validation: null,
      reloadUploadAttachment: true,
      isDisabled: false,
      cash_type: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    "data.payment_method": function (val) {
      if (val) {
        this.payment_method = val;
      }
    },

    collected_user: function (val) {
      if (val) {
        this.data.collected_user_id = val.id;
      } else {
        this.data.collected_user_id = null;
      }
    },
    treasury: function (val) {
      if (val && val.id) {
        this.data.treasury_id = val.id;
        this.data.method_type_id = val.id;
        this.data.method_type_type = 'Treasuries';
      }
    },
    bank: function (val) {
      if (val && val.id) {
        this.data.method_type_id = val.id;
        this.data.method_type_type = 'Bank';
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },
    "data.amount": function (val) {
      if (val) {
        if(String(val).length > 12){
          this.data.amount = String(val).slice(0, 12);
        }
      }
    },
    // '$route'(val) {
    //     if (val.params.id) {
    //         this.idEditing = val.params.id;
    //         this.getData();
    //     }
    // },
  },
  methods: {
    ...timeZoneStructure,
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        type_id: this.data.type_id,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'payment_sales_invoices.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        type_id: this.data.type_id,
        type_type: this.data.type_type,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'payment_sales_invoices.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.code = response.data.data.code;
        this.data.payment_method = response.data.data.payment_method;
        this.data.amount = response.data.data.amount;
        this.data.payment_date = response.data.data.payment_date;
        this.data.payment_status = response.data.data.payment_status;
        this.data.collected_user_id = response.data.data.collected_user_id;
        this.data.ref_no = response.data.data.ref_no;
        this.data.payment_details = response.data.data.payment_details;
        this.data.receipt_notes = response.data.data.receipt_notes;
        this.data.sales_invoice_id = response.data.data.sales_invoice_id;
        this.data.treasury_id = response.data.data.treasury_id;
        this.data.type_type = response.data.data.type_type;
        this.data.currency_id = response.data.data.currency_id;
        this.data.branch_id = response.data.data.branch_id;
        this.data.attachment = response.data.data.attachment;
        this.attachment_url = response.data.data.attachment_url;

        this.data.method_type_id = response.data.data.method_type_id;
        this.data.method_type_type = response.data.data.method_type_type;


        if (this.idEditing) {
          this.data.type_id = response.data.data.type_id;
        }

        this.collected_user = response.data.data.collected_user;
        this.treasury = response.data.data.treasury;
        this.bank = response.data.data.bank;
        this.branch = response.data.data.branch;
        this.reloadUploadAttachment = true;

        this.setCashType(response.data.data.payment_method);
      });
    },

    onPaymentMethodChanged: _.debounce(function (payment_method) {
      this.payment_method = payment_method;
      this.bank = null;
      this.treasury = null;
      this.setCashType(payment_method);
    }, 100),

    setCashType(payment_method){
      let _cash_type = this.payment_methods.find(row => row.id == payment_method);
      if (_cash_type){
        this.cash_type = _cash_type.cash_type;
      }else {
        this.cash_type = null;
      }
    },

    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      }
    },
    async getPaymentMethods() {//credit_payment_method
      await ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getPaymentStatus() {
      ApiService.get(this.mainRouteDependency + "/payment_status").then((response) => {
        this.payment_status_list = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });

    },
    getBanks() {
        ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
          this.banks = response.data.data;
        });

    },
    // getBanks(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
    //       this.banks = response.data.data;
    //     });
    //   } else {
    //     this.banks = [];
    //   }
    // },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getInvoiceData() {
      // let _url = (this.type == 'invoice' ? this.salesInvoiceRoute : this.purchaseInvoiceRoute);
      let _url;
      if (this.type == 'invoice') {
        _url = this.salesInvoiceRoute;
      } else if (this.type == 'purchase') {
        _url = this.purchaseInvoiceRoute;
      } else if (this.type == 'credits') {
        _url = this.SalesCreditRoute;
      } else if (this.type == 'sales_refund') {
        _url = this.SalesRefundRoute;
      } else if (this.type == 'purchase_refund') {
        _url = this.PurchaseRefundRoute;
      } else if (this.type == 'general_sales_refund') {
        _url = this.generalSalesRefundRoute;
      }

      ApiService.get(_url + "/" + this.data.sales_invoice_id).then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.amount = this.type == 'invoice' ? response.data.data.local_total_order : response.data.data.invoice_total;
        if (this.type == 'invoice') {
          // this.data.amount = response.data.data.local_total_order;
          this.data.amount = response.data.data.remain_amount_after_debit ? response.data.data.remain_amount_after_debit :response.data.data.remaining_amount;
        } else if (this.type == 'purchase') {
          this.data.amount = response.data.data.invoice_total;
        } else if (this.type == 'credits') {
          // this.data.amount = response.data.data.credit_total;
          this.data.amount = response.data.data.remaining_amount;
        } else if (this.type == 'sales_refund') {
          // this.data.amount = response.data.data.invoice_refund_total;
          this.data.amount = response.data.data.remaining_amount;
        } else if (this.type == 'purchase_refund') {
          this.data.amount = response.data.data.items_total;
        } else if (this.type == 'general_sales_refund') {
          this.data.amount = response.data.data.remaining_amount;
        }
        this.data.type_type = response.data.data.type_type;
      });
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/29").then((response) => {
        this.data.code = response.data.data.code;
      });
    },
    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    afterSave() {
      this.reloadUploadAttachment = false;
      this.validation = null;
      setTimeout(() => {
        this.reloadUploadAttachment = true;
      }, 10);
    },

    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.branch_id = response.data.data.branch_id;
        this.inventory = response.data.data.inventory;
        this.branch = response.data.data.branch;

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.payment_invoices"), route: '/sales/payment_sales_invoices'}, {title: this.$t("MENU.payment_sales_invoices") , to: {name: 'payment_sales_invoices.index'}}]);
    let promise1 = this.getPaymentMethods();
    this.getCurrencies();
    this.getPaymentStatus();
    this.getTreasuries();
    this.getInventories();
    this.getBranches();
    this.getBanks();

    if (this.idEditing) {
      Promise.all([promise1]).then(() => {
        this.getData();
      })
    } else {
      // this.data.payment_date = new Date().toISOString().slice(0, 10);
      this.getTimeZone().then((res)=>{
        this.data.payment_date = res;
      });
      let promise = this.defaultDataForUser();
      Promise.all([promise, promise1]).then(() => {
        this.getInvoiceData();
        this.getCode();
      })
    }

  },
};
</script>